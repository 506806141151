<template>
  <div class="nav_container">
    <div
      class="nav_wrap"
      :style="{ padding: `${16 * page_ratio}PX ${60 * page_ratio}PX` }"
      :class="header_class_name"
    >
      <div class="nav">
        <div
          class="nav_loge"
          @click="goHome"
          :style="{
            transform: `scale(${page_ratio})`,
            transformOrigin: 'left',
          }"
        >
          <div class="logo">
              <template  v-if="isLarePass">
                <img
                  src="../../assets/mobile2/logo.png"
                  class="logo-icon"
                  v-if="header_class_name[1] === 'text_light'"
                  alt="logo"
                />
                <img v-else 
                  class="logo-icon"
                src="../../assets/mobile2/logo_dark.png" alt="logo" />
            </template>
            <template v-else>
                <img
                  v-if="header_class_name[1] === 'text_light'"
                  class="logo-text"
                  src="../../assets/v2/logo_text.png"
                  alt=""
                />
                <img v-else
                class="logo-text"
                src="../../assets/v2/logo_text_dark.png" alt="" />
            </template>
          </div>

        </div>

        <div
          class="nav_list_wrap"
          :style="{ transform: `scale(${page_ratio})` }"
        >
          <ul
            class="nav_list"
            :class="slideUp ? 'nav_list_ani_hide' : 'nav_list_ani_show'"
          >
            <li
              class="nav_list_li"
              :class="{ active_nav: activeNav === val.id }"
              v-for="val in NavList"
              :key="val.id"
              :data-to="val.id"
              @click="goMenu(val.id)"
            >
              {{ val.name }}
            </li>
          </ul>
        </div>

        <div
          class="nav_right"
          :style="{
            transform: `scale(${page_ratio})`,
            transformOrigin: 'right',
          }"
        >
          <div class="nav_github_text">
            <div
              class="nav_github_target"
              :class="slideUp ? 'nav_github_ani_hide' : 'nav_github_ani_show'"
            >
              <div class="nav_github" @click="goMenu(100)">
                <img
                  v-if="isDark"
                  src="../../assets/v2/github.svg"
                  alt="github"
                />
                <img
                  v-else-if="moreScreenHeight"
                  src="../../assets/v2/github-light.svg"
                  alt="github"
                />
                <img v-else src="../../assets/v2/github.svg" alt="github" />
                <span class="github-text">Github</span>
              </div>
              <Language
                :dark="header_class_name[1] === 'text_light'"
                :slideUp="slideUp"
              ></Language>
            </div>
          </div>

          <div class="get_start">
            <get_started
              :light="header_class_name[1] === 'text_dark'"
              @click="goMenu(200)"
            ></get_started>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed, onBeforeMount } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useI18n } from "vue-i18n";
import get_started from "./get_started_mini.vue";
import { page_ratio } from "@/stores/platform";
import link from "@/common/link";
import Language from "./language.vue";
import { RouteName } from "../../common/constant";
import { lang_zh } from "@/utils/lang";
const route = useRoute();
onBeforeMount(() => {
  activeNav.value = route.name;
});
const nav_height = computed(() => 56 * page_ratio.value);
const isDark = computed(() => route.name === RouteName.ZERO);
console.log("isDark", isDark);
const { t } = useI18n();

const router = useRouter();
let NavList = computed(() => {
  const list1 = [
    {
      name: t("home"),
      id: RouteName.HOME,
    },
    {
      name: t("docs"),
      id: 1,
    },
    {
      name: t("market"),
      id: 2,
    },
    {
      name: t("blog"),
      id: 3,
    },
    {
      name: t("space"),
      id: 4,
    },
  ];
  const list2 = [
    {
      name: t("Zero"),
      id: RouteName.ZERO,
    },
  ];

  const list3 = [
    {
      name: t("download.termipass"),
      id: RouteName.DOWNLOAD,
    },
  ];
  return lang_zh.value
    ? list1.concat(list2).concat(list3)
    : list1.concat(list3);
});

const activeNav = ref(RouteName.HOME);
const slideUp = ref(false);
const moreScreenHeight = ref(false);
const isLastPage = ref(false);
const starting = ref(0);

const isLarePass = computed(()=> route.name === RouteName.DOWNLOAD)

const header_class_name = computed(() => {
  let classNames = ["header_transparent", "text_light"];
  if (route.name === RouteName.HOME) {
    if (slideUp.value || !moreScreenHeight.value) {
      classNames[0] = "header_transparent";
    } else {
      classNames[0] = "header_light";
    }
    if (moreScreenHeight.value) {
      classNames[1] = "text_dark";
    } else {
      classNames[1] = "text_light";
    }
  } else if (route.name === RouteName.ZERO) {
    if (slideUp.value || !moreScreenHeight.value) {
      classNames[0] = "header_transparent";
    } else {
      classNames[0] = "header_dark";
    }
    classNames[1] = "text_light";
  } else if (route.name === RouteName.DOWNLOAD) {
    classNames = ["header_transparent", "text_dark"];
    if (slideUp.value || !moreScreenHeight.value) {
      classNames[0] = "header_transparent";
    } else {
      classNames[0] = "header_light";
    }
    if (isLastPage.value) {
      classNames[1] = "text_light";
    } else {
      classNames[1] = "text_dark";
    }
  }

  return classNames;
});

const windowScroll = () => {
  const screenHeight = document.documentElement.clientHeight;
  let scrollTop =
    window.pageYOffset ||
    document.documentElement.scrollTop ||
    document.body.scrollTop;

  if (scrollTop > screenHeight) {
    moreScreenHeight.value = true;
    isLastPage.value = false;
  } else {
    moreScreenHeight.value = false;
  }

  let scroll = scrollTop - starting.value;
  starting.value = scrollTop;
  if (scroll > 0) {
    slideUp.value = true;
  } else {
    slideUp.value = false;
  }

  if (
    window.innerHeight + window.scrollY >=
    document.body.offsetHeight - nav_height.value
  ) {
    isLastPage.value = true;
    moreScreenHeight.value = false;
  }
};

const goHome = () => {
  router.push("/");
};

const goMenu = (value) => {
  if(value !== 1 && value !== 2 && value !== 3 && value !== 4 && value !== 100 && value !== 200) {
    activeNav.value = value;
  }
  if (value === RouteName.HOME) {
    router.push("/");
  } else if (value === 1) {
    window.open(link.docs_path);
  } else if (value == 2) {
    window.open(link.market_path);
  } else if (value == 3) {
    window.open(link.footer_medium);
  } else if (value == 4) {
    window.open(link.footer_terminus_space);
  } else if (value == 100) {
    window.open(link.footer_github);
  } else if (value == 200) {
    window.open(link.get_started);
  } else if (value == RouteName.ZERO) {
    router.push({
      name: RouteName.ZERO,
    });
  } else if (value == RouteName.DOWNLOAD) {
    router.push({
      name: RouteName.DOWNLOAD,
    });
  }
};

onMounted(() => {
  window.addEventListener("scroll", windowScroll);
});

onUnmounted(() => {
  window.removeEventListener("scroll", windowScroll);
});
const containerHeight = computed(() => `${72 * page_ratio.value}PX`);
</script>

<style scoped>
.nav_container {
  position: relative;
  top: 0PX;
  z-index: 100;
  width: 100vw;
  display: flex;
  justify-content: center;
  max-width: 100%;
}
.nav_wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: v-bind(containerHeight);
}

.header_light {
  background: #ffffff;
}
.header_light .nav_list_li {
  /* color: #232323; */
}

.header_light .nav_loge span {
  /* color: #fff; */
}

.header_light .get_start {
  /* background-color: #FFFFFF; */
  /* color: #232323; */
}

.header_dark {
  background: #09090b;
}

.header_dark .nav_list_li {
  /* color: #FFFFFF; */
}

.header_dark .nav_loge span {
  /* color: #FFFFFF; */
}

/* .header_dark .nav_loge .logo {
  background-color: #09090B;
} */

.header_dark .get_start {
  /* color: #FFFFFF; */
}
.header_transparent {
  background: transparent;
}
.nav {
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.nav_shadow {
  background-color: rgba(255, 255, 255, 0.96);
  box-shadow: 0PX 1PX 10PX #e5e5e5;
}

.nav_loge {
  width: 200PX;
  cursor: pointer;
  display: flex;
  align-items: center;
  position: relative;
}

.nav_loge .logo {
  display: flex;
  align-items: center;
}

.nav_loge .logo .logo-text {
  width: 77PX;
  height: 18PX;
  margin-left: 6px;
}
.nav_loge .logo .logo-icon {
  width: 24PX;
  height: 28PX;
}

.nav_logo_text {
  width: 77PX;
  height: 19PX;
}

.nav_loge_ani_hide {
  animation: logoHideAni 0.4s ease-in-out forwards;
}
@keyframes logoHideAni {
  0% {
    right: 0;
  }
  100% {
    right: 150PX;
  }
}
.nav_loge_ani_show {
  animation: logoShowAni 0.4s ease-in-out forwards;
}

@keyframes logoShowAni {
  0% {
    right: 150PX;
  }
  100% {
    right: 0;
  }
}

.nav_list_wrap {
  position: relative;
}

.nav_list {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

.nav_list_ani_hide {
  animation: navAniHide 0.4s ease-in-out forwards;
}

@keyframes navAniHide {
  0% {
    top: 0;
  }
  100% {
    top: -98PX;
  }
}

.nav_list_ani_show {
  animation: navAniShow 0.4s ease-in-out forwards;
}

@keyframes navAniShow {
  0% {
    top: -98PX;
  }
  100% {
    top: 0;
  }
}

.nav_list_li {
  margin: 0 27PX;
  font-size: 14PX;
  cursor: pointer;
  height: 30PX;
  line-height: 30PX;
  transition: all 0.2s ease-in-out;
  -webkit-transition: all 0.2s ease-in-out;
  -moz-transition: all 0.2s ease-in-out;
  -o-transition: all 0.2s ease-in-out;
  color: #fff;
}

.text_light li.nav_list_li,
.text_light .github-text {
  color: #fff;
}

.text_dark li.nav_list_li,
.text_dark .github-text {
  color: #000;
}
.active_nav {
  color: rgba(255, 91, 40, 1) !important;
}

.nav_right {
  position: relative;
  width: 264PX;
  height: 32PX;
}

.nav_github_text {
  width: 166PX;
  height: 32PX;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  cursor: pointer;
}

.nav_github_target {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  left: 0;
  width: 100%;
  top: 0;
  bottom: 0;
  margin: 0;
}
.nav_github {
  display: flex;
  align-items: center;
}
.nav_github span {
  font-size: 16PX;
  font-style: normal;
  font-weight: 500;
  line-height: 20PX;
  letter-spacing: 0.2PX;
  margin-left: 6PX;
}

.nav_github_ani_hide {
  animation: githubHideAni 0.4s ease-in-out forwards;
}
@keyframes githubHideAni {
  0% {
    left: 0;
    opacity: 1;
  }
  40% {
    opacity: 0.2;
  }
  45% {
    opacity: 0;
  }
  100% {
    left: 174PX;
    opacity: 0;
  }
}
.nav_github_ani_show {
  animation: githubShowAni 0.4s ease-in-out forwards;
}

@keyframes githubShowAni {
  0% {
    left: 174PX;
    opacity: 0;
  }
  55% {
    opacity: 0.2;
  }
  60% {
    opacity: 1;
  }
  100% {
    left: 0;
    opacity: 1;
  }
}

.get_start {
  box-sizing: border-box;
  font-size: 14PX;
  text-align: center;
  cursor: pointer;
  margin-left: 20PX;
  border-radius: 8PX;
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin: 0;
  display: flex;
  align-items: center;
}
</style>
